import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import ProjectsVideo from '../assets/videos/projects.mp4'
import Video from '../components/Video'
import Project from '../components/Project'
import ContentContainer from '../components/ContentContainer'

const ProjectsPage = ({ data }) => {
  const projects = data.allMarkdownRemark.edges

  return (
    <Layout noTopPadding>
      <SEO title="Artist & Projects" />
      <Video Src={ProjectsVideo} />
      <ContentContainer>
        {projects.map(({ node: project }) => (
          <Project
            title={project.frontmatter.projectTitle}
            artist={project.frontmatter.artist}
            path={`/projects${project.fields.slug}`}
            image={project.frontmatter.projectThumb.childImageSharp.fluid}
            key={project.id}
          />
        ))}
      </ContentContainer>
    </Layout>
  )
}

export default ProjectsPage

export const ProjectsQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: frontmatter___artist, order: ASC }
      filter: { fileAbsolutePath: { regex: "/projects/" } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            artist
            projectTitle
            projectThumb {
              childImageSharp {
                id
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
