import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { color, font, spacing, breakpoints } from '../../utils/constants'

const ProjectLink = styled(props => <Link {...props} />)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  color: ${color.baseDark};
  text-decoration: none;
`

const MetaContainer = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  height: 100%;
  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`

const ArtistName = styled.h3`
  margin: 0;
`

const ProjectTitle = styled.h2`
  position: relative;
  margin: 0;
  padding-left: ${spacing.md};
  font-family: ${font.light};
  font-size: 5vw;

  &:before {
    content: ' ';
    position: absolute;
    top: 3.0555556vw;
    left: 0;
    display: block;
    height: 0.3472222vw;
    width: 2.8rem;
    background-color: ${color.baseDark};
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 6.5vw;
  }

  @media (min-width: ${breakpoints.desktopHD}) {
    font-size: 4rem;
  }
`

const TitleUnderline = styled.span`
  border-bottom: 0px solid ${color.baseDark};

  ${ProjectLink}:hover & {
    border-bottom: 0.2777778vw solid ${color.baseDark};
  }
`

const ImageContainer = styled.div`
  width: 75%;
  margin-left: auto;
  opacity: 0;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;

  ${ProjectLink}:hover & {
    opacity: 0.7;
  }
`

export default function Project(props) {
  return (
    <ProjectLink to={props.path}>
      <ImageContainer>
        <Img fluid={props.image} />
      </ImageContainer>
      <MetaContainer>
        <ArtistName>
          <TitleUnderline>{props.artist}</TitleUnderline>
        </ArtistName>
        <ProjectTitle>
          <TitleUnderline>{props.title}</TitleUnderline>
        </ProjectTitle>
      </MetaContainer>
    </ProjectLink>
  )
}
