import React from 'react'

export default function Video(props) {
  return (
    <video
      src={props.Src}
      type="video/mp4"
      loop
      autoPlay
      muted
      playsInline
      style={{ width: `100%` }}
    ></video>
  )
}
